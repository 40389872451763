import React from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink"

import menuIconWhite from "../images/menu-white.png"
import menuIconBlack from "../images/menu-black.png"

const MenuFixed = ({ handleShow, theme }) => {
  return (
    <div>
<AniLink
  style={{
    height: "30px",
    width: "30px",
    zIndex: `300`,
  }}
  to="/full-menu/"
  className="menu-fixed d-block d-md-none"
  cover
  bg="var(--gradient-background)"
  direction="right"
  top="entry"
  duration={1}
>
 <img className="d-block d-md-none menu-fixed" src={theme == "light" ? menuIconBlack : menuIconBlack} />
</AniLink>

<img
  className="d-md-block d-none menu-fixed"
  src={theme == "light" ? menuIconBlack : menuIconBlack}
  onClick={handleShow}
/>
</div>
  )
}

export default MenuFixed



