import { LOCALES } from '../locations';

export default {
    [LOCALES.ENGLISH]: {
        'growingApptegy': 'is growing {value}.',
        'tylerVawser': 'Tyler Vawser',
        'letsTalk': 'Let\'s talk.',
        'setCall': 'Set up a call',
        'previouslyAt': 'Previously at',
        'is sleeping.': 'is sleeping.',
        'is waking up.': 'is waking up.',
        'is drinking coffee.': 'is drinking coffee.',
        'is reading.': 'is reading.',
        'is answering emails.': 'is answering emails.',
        'is on a Zoom call.': 'is on a Zoom call.',
        'is meeting interesting people.': 'is strategizing.',
        'is going for a walk.': 'is going for a walk.',
        'is eating lunch.': 'is eating lunch.',
        'is asking questions.': 'is asking questions.',
        'is focused.': 'is focused.',
        'is focused.': 'is typing...',
        'is on the phone.': 'is on the phone.',
        'is on Slack.': 'is on Slack.',
        'is eating dinner with his family.': 'is eating dinner with his family.',
        'is playing games.': 'is playing games.',
        'is cleaning up.': 'is cleaning up.',
        'is reading a book.': 'is at the gym.',
        'is preparing for tomorrow.': 'is preparing for tomorrow.',
        'is getting sleepy.': 'is tired.',
        'twitter': 'Twitter',
        'email': 'Email',
        'linkedIn': 'LinkedIn',
        'posts': 'Posts',
        'favorites': 'Favorites',
        'now': 'Now',
        'about': 'About',
        'en': 'English',
        'es': 'Spanish',
        'jp': 'Japanese',
        'waitingChoice': 'Tyler is waiting to see what you\'ll pick first.'
    }
}
