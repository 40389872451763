import { LOCALES } from '../locations';

export default {
    [LOCALES.SPANISH]: {
        'growingApptegy': 'está haciendo crecer {value}.',
        'tylerVawser': 'Tyler Vawser',
        'letsTalk': 'Hablemos.',
        'setCall': 'Agendemos una llamada',
        'previouslyAt': 'Previamente en',
        'is sleeping.': 'está durmiendo.',
        'is waking up.': 'está despertando',
        'is drinking coffee.': 'está tomando café',
        'is reading.': 'está leyendo.',
        'is answering emails.': 'está respondiendo emails.',
        'is on a Zoom call.': 'está en una llamada de Zoom.',
        'is meeting interesting people.': 'está conociendo personas interesantes.',
        'is going for a walk.': 'está caminando un rato.',
        'is eating lunch.': 'está comiendo.',
        'is asking questions.': 'está haciendo preguntas.',
        'is focused.': 'está concentrado.',
        'is on the phone.': 'está al teléfono.',
        'is on Slack.': 'está en Slack.',
        'is eating dinner with his family.': 'está cenando con su familia.',
        'is playing games.': 'está jugando juegos.',
        'is cleaning up.': 'está limpiando.',
        'is reading a book.': 'está leyendo un libro.',
        'is preparing for tomorrow.': 'se está preparando para mañana.',
        'is getting sleepy.': 'está por dormir.',
        'twitter': 'Twitter',
        'email': 'Correo Electrónico',
        'linkedIn': 'LinkedIn',
        'posts': 'Publicaciones',
        'favorites': 'Favoritos',
        'now': 'Ahora',
        'about': 'Sobre mi',
        'en': 'Inglés',
        'es': 'Español',
        'jp': 'Japonés',
        'waitingChoice': 'Tyler está esperando tu elección.'
    }
}